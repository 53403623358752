import {ArcRotateCamera, Mesh, Scene, SceneLoader, Vector3} from "@babylonjs/core";
import {AnimBlender} from "@/js/animBlender";
import {TpsInput} from "@/js/thirdPerson/tpsInput";


export class PlayerTPS extends Mesh {
    constructor(name: string, scene: Scene) {
        super(name, scene)
        this.camera = new ArcRotateCamera('player camera', 0, 1.5, 4, Vector3.Up().scale(1.6))
        this.camera.inputs.add(new TpsInput(this))
        this.camera.attachControl()
        this.camera.parent = this
        this.camera.checkCollisions = false
        this.camera.panningDistanceLimit = 0.01
        this.camera.radius = 4
        this.camera.upperBetaLimit = Math.PI * 0.5
        this.camera.lowerRadiusLimit = 3
        this.camera.upperRadiusLimit = 10
        this.camera.wheelPrecision = 25
        this.initMesh().then()
    }

    async initMesh() {
        SceneLoader.ImportMesh('', '', 'uebot.glb', this._scene,
            (meshes, particle, askeleton, animtions, nodes) => {
                this._scene.activeCamera = this.camera
                this.mesh = meshes[0]
                this.mesh.parent = this
                this.animBlender = new AnimBlender(animtions, this._scene, 'idle')
                this.ellipsoid = new Vector3(0.5, 1, 0.5)
                this.ellipsoidOffset = new Vector3(0, 1, 0)
            })
    }

}
