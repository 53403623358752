import {
    Color3,
    CubeTexture,
    Mesh,
    MeshBuilder,
    PBRMaterial,
    Scene,
    SceneLoader,
    Texture,
    Vector3
} from "@babylonjs/core";
import '@babylonjs/loaders/glTF'
import {SceneProcess} from "@/js/sceneProcess";
import {PlayerTPS} from "@/js/thirdPerson/playerTPS";
import {LoadingManage} from "@/js/lodingManage";

export class Scene1Builder {
    static async CreateScene(scene: Scene, loadingManage: LoadingManage) {
        scene.createDefaultCamera(1, 1, 1)
        let ground = MeshBuilder.CreateGround('ground', {width: 100, height: 100})
        ground.checkCollisions = true
        ground.visibility = false

        scene.environmentTexture = new CubeTexture('environment.env', scene)
        scene.environmentIntensity = 1
        scene.createDefaultSkybox(scene.environmentTexture)

        scene.imageProcessingConfiguration.toneMappingEnabled = true

        SceneLoader.Append('', 'model.glb', scene, e => {
            scene.getMeshByName('__root__').position.y -= 2
            scene.getMeshByName('__root__').scaling.scale(2)
            SceneProcess.runProcess(scene, 'collision', e => {
                if (e instanceof Mesh) {
                    e.checkCollisions = true
                    e.isPickable = false
                    e.visibility = 0
                }
            })
            new PlayerTPS('player', scene).position = new Vector3(7, 0.1, -10)
            scene.ambientColor = Color3.White()
            scene.materials.filter(mat => {
                if (mat instanceof PBRMaterial && mat.emissiveTexture) {
                    mat.lightmapTexture = mat.emissiveTexture
                    mat.emissiveTexture = undefined
                    mat.emissiveIntensity = 0
                    mat.ambientColor = Color3.White()
                    mat.useLightmapAsShadowmap = true
                    mat.metallic = 0
                }
            })
            let paints: Mesh[] = scene.meshes.filter(mesh => mesh.name.indexOf('paint') > -1)
            let rand = Math.floor(Math.random() * paints.length)
            console.log(paints[rand]);
            let logoPaint = new PBRMaterial('logo paint', scene)
            logoPaint.albedoTexture = new Texture('logo.jpg', scene, undefined, false)
            logoPaint.metallic = 0
            logoPaint.roughness = 0.5
            paints[rand].material = logoPaint
        }, evt => {
            let loadedPercent = 0;
            if (evt.lengthComputable) {
                loadedPercent = (evt.loaded * 100 / evt.total).toFixed(0);
            }
            else {
                let dlCount = evt.loaded / (1024 * 1024);
                loadedPercent = Math.floor(dlCount * 100.0) / 100.0;
            }
            loadingManage.sendProgress(loadedPercent)
        })
    }
}
