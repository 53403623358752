import {Engine, Scene} from "@babylonjs/core";
import {Scene1Builder} from "@/js/scene1Builder";
import '@babylonjs/inspector'
import {LoadingManage} from "@/js/lodingManage";

export class Game {
    constructor(canvas: HTMLCanvasElement) {
        this.engine = new Engine(canvas)
        this.scene = new Scene(this.engine)
        this.loadingManage = new LoadingManage()
        this.engine.loadingScreen = this.loadingManage

        Scene1Builder.CreateScene(this.scene, this.loadingManage).then(r => {
            this.engine.runRenderLoop(() => {
                this.scene.render()
            })
        })

        window.addEventListener("resize", () => {
            this.engine.resize()
        })

    }
}
