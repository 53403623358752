<template>
  <div id="app">
    <el-dialog
        title="操作提示"
        width="70%"
        :visible.sync="showHelp"
    >
      <slot></slot>
      <el-image src="help.png"></el-image>
    </el-dialog>
    <div id="right-menu">
      <ul>
        <li @click="switchFullScreen" class="menu-item">
          <span class="iconfont">&#xe649;</span>
        </li>
        <li v-bind:class="{music:musicEnable}" @click="toggleMusic();" class="menu-item">
          <span class="iconfont">&#xe644;</span>
        </li>
        <!--        <li @click="showHelp = true" class="menu-item" >-->
        <!--          <span class="iconfont">&#xe619;</span>-->
        <!--        </li>-->
        <li class="menu-item" :class="{praise:isPraise== 1? true:false}" @click="setPraise">
          <span class="iconfont">&#xe61c;</span>
        </li>
        <!--        <li class="menu-item" @click="isComment = !isComment"  :class="{commentShow:isComment?true:false}">-->
        <!--          <span class="iconfont">&#xe63a;</span>-->
        <!--        </li>-->
      </ul>
    </div>
    <Comment v-show="isComment" class="comment" :userData="userData" :commentChange="isComment"/>
    <canvas id="canvas">

    </canvas>
    <div class="loading-program">
      <transition name="el-fade-in">
        <el-progress class="" type="circle" :percentage="percentage" v-show="loadingShow"
                     :status="status"></el-progress>
      </transition>
    </div>
  </div>
</template>

<script>
import Comment from './components/Comment.vue';
import {Game} from "@/js/game";

export default {
  name: 'App',
  components: {
    Comment
  },
  data() {
    return {
      percentage: 0,
      loadingShow: true,
      status: '',
      isComment:false,
      userData:[],
      musicEnable: false,
      showHelp:false,
      isPraise: 2,
      player:new Audio('autumn-talk.mp3')
    }
  },
  methods: {
    setPraise() {
      var praise = localStorage.getItem('isPraise')

      if (praise == null) {
        localStorage.setItem('isPraise', 1)
        this.isPraise = 1
      } else {
        if (praise == 1) {
          localStorage.setItem('isPraise', 2)
          this.isPraise = 2
        } else {
          localStorage.setItem('isPraise', 1)
          this.isPraise = 1
        }
      }
    },
    changeCommentStatus(status) {
      this.isComment = status
    },
    switchFullScreen() {
      if (document.fullscreenElement) {
        document.exitFullscreen()
      }
      this.$root.$el.requestFullscreen({})
    },
    toggleMusic() {
      var musicEnable = this.musicEnable
      if(musicEnable){
        this.musicEnable = false;
        this.player.pause();
      }else{
        this.musicEnable = true;
        this.player.play();
      }
      
    },
    closeModelShow() {
      window.game.modelShow.hide()
      window.game.modelShowimag.hide()
    },
  },
  mounted() {
    window.game = new Game(document.getElementById('canvas'))
    window.game.loadingManage.onDisplayObs.add(e => {
      console.log('loadStart')
    })
    window.game.loadingManage.onHideObs.add(e => {
      console.log('loadEnd')
      this.percentage = 100;
      this.status = 'success';
      setTimeout(() => {
        this.loadingShow = false;
      }, 300)
    })
    window.game.loadingManage.onLoadingProgress.add(progress => {
      console.log('loading: ' + progress)
      var t = 0;
      if(progress > 90){
        t = 90;
      }else{
        t = progress
      }
      this.percentage = Number(t)
    })

  }
}
</script>

<style>
* {
  padding: 0;
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#app, canvas, html, body, html {
  width: 100%;
  height: 100%;
}

canvas {
  z-index: 1000;
}

.loading-program {
  z-index: 1200;
  position: fixed;
  width: 100%;
  top: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.el-progress--circle .el-progress__text, .el-progress--dashboard .el-progress__text {
  color: rgb(64, 158, 255);
}

.music {
  display: block;
  animation: music 2s linear infinite;
}

@keyframes music {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(90deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(270deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'iconfont';  /* Project id 3013934 */
  src: url('//at.alicdn.com/t/font_3013934_v73wlc8zol.woff2?t=1641885538760') format('woff2'),
  url('//at.alicdn.com/t/font_3013934_v73wlc8zol.woff?t=1641885538760') format('woff'),
  url('//at.alicdn.com/t/font_3013934_v73wlc8zol.ttf?t=1641885538760') format('truetype');
}

.iconfont {
  font-family: "iconfont", serif !important;
  font-size: 18px;
  padding: 8px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #F8F8FF;
}


.menu-item {
  padding: 5px;
  font-size: 20px;
  color: white;
  cursor: pointer;
  margin-bottom: 20px;
}

.menu-item.praise {
  color: #1989FA;
}

.menu-item.commentShow {
  color: #1989FA;
}

li {
  list-style-type: none;
}

#right-menu {
  position: fixed;
  display: -webkit-flex; /* Safari */
  width: 30px;
  height: 200px;
  z-index: 1500;
  top: 32px;
  right: 24px;
}

.comment {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 60%;
  max-width: 600px;
}

.top-icon {
  position: fixed;
  top: 0px;
  right: 0px;
}
</style>
