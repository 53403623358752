<template>
  <div>
      <div class="comment-ul">
          <vue-seamless-scroll :data="commentList" class="seamless-warp"  :class-option="classOption">
            <ul>
                <li v-for="(item,index) in commentList" :key="index" >
                    <div>{{item.content}}</div>
                    
                </li>
            </ul>
        </vue-seamless-scroll>
      </div>
      <div class="comment-send">
          <div>
              <div>
                  <input type="text" v-model="content"/>
              </div>
              <div>
                  <div @click="sendMsg">发表</div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import {  getCommentList,sendComment } from "@/js/api.js";
import vueSeamlessScroll from 'vue-seamless-scroll';
export default {
  name: 'Comment',
  components: {
      "vue-seamless-scroll":vueSeamlessScroll 
  },
  props: {
    msg: String,
    userData: {
        Type:Object,
        default: ()=>{}
    },
    commentChange: Boolean
  },
  data() {
    return {
        commentList: [],
        content: '',
      
    }
  },
  created(){
    this.getCommentList();
  },
  mounted () {
   
  },
  methods: {
      getCommentList(){
        getCommentList({"tem_id":1}).then( res => {
            if(res.data.error == 0){
                this.commentList = res.data.list.commentData
            }
        })
      },
      sendMsg(){
          var content = this.content
          if(content == ''){
              this.$message.error('请填写评论内容');
              return;
          }
          sendComment({
              'mch_id':1,
              'tem_id':1,
              'content':this.content,
              'userData':this.userData
          }).then(res => {
                if(res.data.error == 0){
                    this.content = ''
                    this.getCommentList();
                }
          })
          console.log(this.userData)
      }
  },
  computed:{

      classOption() {
        return {
          step: .5, // 数值越大速度滚动越快
          // limitMoveNum: 10, // 开始无缝滚动的数据量 this.dataList.length
          hoverStop: true, // 是否开启鼠标悬停stop
          direction: 1, // 0向下 1向上 2向左 3向右
          openWatch: true, // 开启数据实时监控刷新dom
          singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
          singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
          waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
        };
      },
  },
  watch:{
      commentChange(n,v){
          if(n){
              this.getCommentList();
          }
      }
  }
}
</script>

<style scoped>
    .comment-float{

        height: 320px;
    }
    .comment-float > div{

        background-color: rgba(0,0,0,0.45);
        height: 36px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.85);
        line-height: 36px;
        margin-bottom: 8px;
        border-radius: 18px;
        max-width: 560px;
        text-align: left;
        padding-left: 16px;
    }


    .comment-ul{
        height: 320px;
        overflow: hidden;
    }
    .comment-ul ul{

    }
    .comment-ul ul > li{
        display:flex;
        justify-content: flex-end;
    }
    .comment-ul ul > li > div{
        display: inline-block;
        background-color: rgba(0,0,0,0.45);
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.85);
        line-height: 36px;
        margin-bottom: 8px;
        border-radius: 18px;
        max-width: 560px;
        text-align: left;
        padding-left: 16px;
        padding-right: 16px;
    }


    .comment-send{
        margin-top: 8px;
        height: 48px;
        width: 100%;
    }
    .comment-send > div{
        height: 24px;
        display: flex;
        width: 100%;
    }
    .comment-send > div > div:first-child{
        height: 36px;
        width: 90%;
      
    }
    .comment-send > div > div:first-child > input{
        height: 36px;
        width: 100%;
        background-color: rgba(0,0,0,0.45);
        border: none;
        outline:none;  
        border-radius: 18px 0 0 18px;
   
    }
    .comment-send > div > div:nth-child(2){
        height: 36px;
        width:75px;
        background-color: rgba(0,0,0,0.45);
        border-radius: 0 18px 18px 0;
        display: flex;
        align-items: center;
    }
    .comment-send > div > div:nth-child(2) > div{
        border-left: 1px solid rgba(255, 255, 255, 0.85);
        width: 75px;
        text-align: center;
        font-size: 14px;
        cursor: pointer;
    }
</style>
